<template>
  <div>
    <bl-illustration-av
      v-if="illustrationName"
      :name="illustrationName"
      width="264"
      height="164"
      class="u-mrgn-bottom--7"
    />
    <bl-text-av is-bolder>
      {{ caption }}
    </bl-text-av>
    <bl-text-av v-if="subtext" class="u-mrgn-top--4">
      {{ subtext }}
    </bl-text-av>
    <bl-button-av
      v-if="buttonText"
      is-block
      class="u-mrgn-top--4"
      variation="primary"
      size="small"
      @click="onClickButton"
    >
      {{ buttonText }}
    </bl-button-av>
  </div>
</template>

<script>
import BlIllustrationAv from '@bukalapak/bazaar-visual-1.13.0/dist/components/BlIllustrationAv';
import BlTextAv from '@bukalapak/bazaar-dweb-latest/dist/components/BlTextAv';
import BlButtonAv from '@bukalapak/bazaar-dweb-latest/dist/components/BlButtonAv';

export default {
  name: 'EmptyState',
  components: {
    BlIllustrationAv,
    BlTextAv,
    BlButtonAv,
  },
  props: {
    caption: {
      type: String,
      default: '',
    },
    subtext: {
      type: String,
      default: '',
    },
    illustrationName: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      default: '',
    },
  },
  methods: {
    onClickButton() {
      this.$emit('onClick')
    },
  },
};
</script>

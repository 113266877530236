<template>
  <div>
    <bl-sheet-mv
      auto-height
      :active.sync="showModal"
      class="bl-sheet--no-handler bl-sheet--hide-icon bl-sheet--navbar-no-border-bottom"
      style="z-index: 99999;"
    >
      <div slot="content" class="u-pad-v--4 u-pad-h--6 u-mrgn-bottom--2 u-txt--center">
        <div v-if="isLoading" class="u-align-center">
          <bl-spinner-av is-small />
        </div>
        <div
          v-else-if="isError"
          class="u-pad-v--3 u-pad-h--4"
        >
          <empty-state
            title="Terjadi kesalahan, silahan coba kembali!"
            action="Muat ulang"
            @click-action="$bus.$emit('INIT_MITRA_WALLET_CONSENT')"
          />
        </div>
        <div v-else>
          <bl-illustration-av
            :name="mitraWalletConsent.illustrationName"
            :alt="mitraWalletConsent.title"
            class="u-mrgn-bottom--4"
            width="200"
            height="124"
          />
          <bl-text-av class="u-mrgn-top--6" variation="subheading-20">
            {{ mitraWalletConsent.title }}
          </bl-text-av>
          <bl-text-av class="u-mrgn-top--2">
            {{ mitraWalletConsent.desc }}
          </bl-text-av>
          <div class="u-border-top--1--sand-dark u-mrgn-top--4 u-mrgn-bottom--2"></div>
          <div class="u-align-left">
            <bl-checkbox-av
              v-model="tncAccepted"
              :disabled="consentStatus.recordMitraAccountRegistration.loading"
              class="u-align-items--flex-start u-mrgn-bottom--3"
            >
              <bl-text-av tag="span" variation="caption-12">
                Saya menyetujui
              </bl-text-av>
              <bl-text-av :href="mitraWalletConsent.tncUrl" variation="caption-12" target="_blank" class="u-fg-blue">
                Ketentuan Pengguna
              </bl-text-av>
              <bl-text-av tag="span" variation="caption-12"> dan mendaftarkan akun saya sebagai Mitra Bukalapak</bl-text-av>
            </bl-checkbox-av>
            <bl-flex-container-mv align-items="center">
              <bl-flex-item-av grow="1" shrink="1">
                <bl-button-av
                  :disabled="!tncAccepted || consentStatus.recordMitraAccountRegistration.loading"
                  variation="primary"
                  fullwidth
                  @click="onClickButton"
                >
                  {{ mitraWalletConsent.buttonText }}
                </bl-button-av>
              </bl-flex-item-av>
            </bl-flex-container-mv>
          </div>
        </div>
      </div>
    </bl-sheet-mv>

    <verified-phone-modal
      :is-active.sync="showUnverifiedPhoneModal"
      title="Verifikasi Nomor Handphone"
      desc="Sebelum melakukan aktivasi saldo mitra, harap verifikasi nomor handphone dulu."
      style="z-index: 100000;"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { getErrorMessage } from 'shared_js/helpers/error-helper';
import { LOCAL_STORAGE_KEYS } from 'shared_js/constant/local_storage';
import curry from 'shared_js/helpers/curry-helper';
import userApis from 'shared_js/api/public/users';
import BlSpinnerAv from '@bukalapak/bazaar-mweb/dist/components/BlSpinnerAv';
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';
import BlCheckboxAv from '@bukalapak/bazaar-mweb/dist/components/BlCheckboxAv';
import BlSheetMv from '@bukalapak/bazaar-mweb/dist/components/BlSheetMv';
import BlButtonAv from '@bukalapak/bazaar-mweb/dist/components/BlButtonAv';
import BlFlexContainerMv from '@bukalapak/bazaar-mweb/dist/components/BlFlexContainerMv';
import BlFlexItemAv from '@bukalapak/bazaar-mweb/dist/components/BlFlexItemAv';
import BlIllustrationAv from '@bukalapak/bazaar-visual-1.13.0/dist/components/BlIllustrationAv';
import MitraWalletMixin from 'bukasend_mobile/mixins/mitra-wallet';
import EmptyState from 'shared_js/components/mobile/EmptyState';
import PhoneVerificationMixin from 'bukasend_mobile/mixins/phone-verification';
import VerifiedPhoneModal from '../Kyc/VerifiedPhoneModal';

export default {
  name: 'MwebMitraWalletConsentModal',
  components: {
    BlSpinnerAv,
    BlTextAv,
    BlSheetMv,
    BlButtonAv,
    BlIllustrationAv,
    BlCheckboxAv,
    BlFlexContainerMv,
    BlFlexItemAv,
    VerifiedPhoneModal,
    EmptyState,
  },
  mixins: [MitraWalletMixin, PhoneVerificationMixin],
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tncAccepted: false,
      showUnverifiedPhoneModal: false,
    };
  },
  computed: {
    ...mapState('user', ['consentStatus', 'consentAgreement', 'fetchMitraAgentStatus']),
    ...mapState('toggle', ['toggleRegisterMitraAgentPhoneVerifiedEnabled']),
    showModal: {
      get() {
        return this.isActive;
      },
      set(value) {
        this.$emit('update:isActive', value);
      }
    },
    isLoading() {
      return this.fetchMitraAgentStatus === null || curry(['consentAgreement', this.mitraWalletConsent.productId, 'loading'], this.consentStatus);
    },
    isError() {
      return this.fetchMitraAgentStatus === false || curry(['consentAgreement', this.mitraWalletConsent.productId, 'error'], this.consentStatus);
    }
  },
  created() {
    this.tncAccepted = Boolean(JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.MITRA_WALLET_CONSENT_TNC_ACCEPTED)));
  },
  watch: {
    tncAccepted(value) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.MITRA_WALLET_CONSENT_TNC_ACCEPTED, JSON.stringify(Boolean(value)));
    },
  },
  methods: {
    ...mapActions('user', ['fetchMitraAgent', 'recordBukasendConsent']),
    async onClickButton() {
      if (this.toggleRegisterMitraAgentPhoneVerifiedEnabled && !this.isPhoneVerified) {
        this.showUnverifiedPhoneModal = true;
        return;
      }

      try {
        await userApis.registerBukasendMitraAgent();
        await this.fetchMitraAgent({ forceReload: true });
        await this.recordBukasendConsent({ agreementId: this.consentAgreement[this.mitraWalletConsent.productId].id });
        this.showModal = this.showMitraWalletConsent;
      } catch (error) {
        this.$bus.$emit('OPEN_SNACKBAR', {
          message: getErrorMessage(error),
          isError: true,
        });
      }
    },
  },
};
</script>

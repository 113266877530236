import { API_BASE_URL } from 'shared_js/constant/bukasend';

const EXCLUSIVE_URL = `${API_BASE_URL}/_exclusive`;

const interfaces = {
  getBukaSendUsers(params) {
    return this.get(`${EXCLUSIVE_URL}/open-shipments/users`, 'user', { params });
  },
  getUserByUsername(username = '') {
    return this.get(`${EXCLUSIVE_URL}/users/username/${username}`, 'user');
  },
  getUserById(id) {
    return this.get(`${EXCLUSIVE_URL}/users/${id}`, 'user');
  },
  getUserConsent(params) {
    return this.get(`${EXCLUSIVE_URL}/users/consents`, 'user', { params });
  },
  getLatestUserConsentAgreement(productId) {
    return this.get(`${EXCLUSIVE_URL}/consents/products/${productId}/agreements/latest`, 'user');
  },
  recordUserConsent(data) {
    return this.put(`${EXCLUSIVE_URL}/users/consents`, 'user', { data });
  },
};

export default interfaces;

import userApis from 'shared_js/api/public/users';
import exclusiveUserApis from 'shared_js/api/exclusive/users';
import agentApis from 'shared_js/api/public/agents';
import curry from 'shared_js/helpers/curry-helper';

export default {
  async fetchCurrentUser({ commit, state }) {
    if (state.profile !== null) {
      return;
    }

    commit('SET_FETCH_STATUS', null);

    try {
      const response = await userApis.getCurrentUser();
      let status = false;

      if (response.meta.http_status === 200) {
        status = true;
        const { data } = response;

        commit('SET_PROFILE', data);
      }
      commit('SET_FETCH_STATUS', status);
    } catch (e) {
      commit('SET_FETCH_STATUS', false);
    }
  },
  async fetchMitraAgent({ commit, state }, params = {}) {
    const { forceReload } = params;
    if (!forceReload && state.mitraAgent !== null) {
      return;
    }

    commit('SET_FETCH_MITRA_AGENT_STATUS', null);

    try {
      const response = params.apiResult || (await agentApis.getMe());
      const { data, meta } = response;
      if (meta.http_status === 200) {
        commit('SET_MITRA_AGENT', data);
      }
      commit('SET_FETCH_MITRA_AGENT_STATUS', [200, 404].includes(meta.http_status));
    } catch (error) {
      commit('SET_FETCH_MITRA_AGENT_STATUS', Boolean(error.response && error.response.status === 404));
    }
  },
  async fetchBukasendMitraAgent({ commit, state }, params = {}) {
    const { forceReload } = params;
    if (!forceReload && state.bukasendMitraAgent !== null) {
      return;
    }

    commit('SET_FETCH_BUKASEND_MITRA_AGENT_STATUS', null);

    try {
      const response = params.apiResult || (await userApis.retrieveBukasendMitraAgent());
      let status = false;

      if (response.meta.http_status === 200) {
        status = true;
        const { data } = response;

        commit('SET_BUKASEND_MITRA_AGENT', data);
      }
      commit('SET_FETCH_BUKASEND_MITRA_AGENT_STATUS', status);
    } catch (e) {
      commit('SET_FETCH_BUKASEND_MITRA_AGENT_STATUS', false);
    }
  },
  async fetchLatestConsentAgreement({ commit, state }, { productId }) {
    if (
      state.consentAgreement[productId] ||
      curry(['consentStatus', 'consentAgreement', productId, 'loading'], state)
    ) {
      return;
    }

    commit('SET_CONSENT_STATUS_AGREEMENT', { productId, key: 'loading', value: true });
    commit('SET_CONSENT_STATUS_AGREEMENT', { productId, key: 'error', value: false });

    try {
      const responseAgreement = await exclusiveUserApis.getLatestUserConsentAgreement(productId);
      commit('SET_CONSENT_AGREEMENT', { productId, value: responseAgreement.data });
    } catch (error) {
      commit('SET_CONSENT_STATUS_AGREEMENT', { productId, key: 'error', value: true });
      throw error;
    } finally {
      commit('SET_CONSENT_STATUS_AGREEMENT', { productId, key: 'loading', value: false });
    }
  },
  async recordBukasendConsent({ commit }, { agreementId }) {
    commit('SET_CONSENT_STATUS_LOADING', { key: 'recordMitraAccountRegistration', value: true });
    commit('SET_CONSENT_STATUS_ERROR', { key: 'recordMitraAccountRegistration', value: false });

    try {
      await exclusiveUserApis.recordUserConsent({
        agreement_id: agreementId,
        consented: true,
      });
    } catch (error) {
      commit('SET_CONSENT_STATUS_ERROR', { key: 'recordMitraAccountRegistration', value: true });
      throw error;
    } finally {
      commit('SET_CONSENT_STATUS_LOADING', { key: 'recordMitraAccountRegistration', value: false });
    }
  },
};

import { mapState, mapGetters, mapActions } from 'vuex';
import curry from 'shared_js/helpers/curry-helper';
import { isLoggedIn } from 'shared_js/helpers/user-helper';

const ACCOUNT_STATUS_FROZEN = 'frozen';

export default {
  computed: {
    isLoggedIn,
    ...mapState('toggle', ['toggleMitraWalletEnabled', 'toggleMitraWalletAnotherEntryPoint']),
    ...mapState('configuration', ['configuration', 'miscConfig']),
    ...mapState('user', ['mitraAgent', 'fetchMitraAgentStatus', 'bukasendMitraAgent', 'consent']),
    ...mapGetters('configuration', ['isRolePartner']),
    mitraWalletBalance() {
      return curry(['dompet', 'saldo_dompet'], this.mitraAgent) || 0;
    },
    isEligibleForMitraWallet() {
      return Boolean(!this.isRolePartner && this.toggleMitraWalletEnabled);
    },
    isMitraRegistered() {
      return Boolean(this.isEligibleForMitraWallet && this.mitraAgent);
    },
    isMitraWalletFreeze() {
      return curry(['account_status'], this.bukasendMitraAgent) === ACCOUNT_STATUS_FROZEN;
    },
    mitraWalletHelpUrl() {
      return curry(['mitraWallet', 'helpUrl'], this.miscConfig);
    },
    mitraWalletWithdrawalUrl() {
      return curry(['mitraWallet', 'withdrawalUrl'], this.miscConfig);
    },
    mitraWalletTopUpUrl() {
      return curry(['mitraWallet', 'topUpUrl'], this.miscConfig);
    },
    mitraWalletConsent() {
      const { isActive, productId, illustrationName, title, desc, buttonText, tncUrl } =
        curry(['mitraWallet', 'consent'], this.miscConfig) || {};

      return {
        isActive,
        productId,
        illustrationName,
        title,
        desc,
        buttonText,
        tncUrl,
      };
    },
    showEntryPointOnAnotherPlatform() {
      return Boolean(!this.isRolePartner && this.toggleMitraWalletAnotherEntryPoint && this.mitraAgent);
    },
    isEligibleForMitraWalletConsent() {
      return Boolean(this.isEligibleForMitraWallet && this.isLoggedIn && this.mitraWalletConsent.isActive);
    },
    showMitraWalletConsent() {
      return Boolean(this.isEligibleForMitraWalletConsent && !this.mitraAgent);
    },
  },
  methods: {
    ...mapActions('user', ['fetchMitraAgent']),
  },
};

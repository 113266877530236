<template>
  <div>
    <bl-modal-mv
      :is-active.sync="showModal"
      max-width="500px"
      title=""
      :is-dismissible="false"
      :is-show-close-button="false"
      class="bl-modal--no-header"
      style="z-index: 99999;"
    >
      <div slot="tabContent" class="p-24">
        <div v-if="isLoading" class="u-align-center">
          <bl-spinner-av is-small />
        </div>
        <div
          v-else-if="isError"
          class="u-pad-v--3 u-pad-h--4"
        >
          <empty-state
            caption="Terjadi kesalahan, silahkan coba kembali!"
            button-text="Muat ulang"
            @onClick="$bus.$emit('INIT_MITRA_WALLET_CONSENT')"
          />
        </div>
        <div v-else>
          <div class="u-align-center">
            <bl-illustration-av
              :name="mitraWalletConsent.illustrationName"
              width="264"
              height="164"
            />
            <bl-text-av is-bolder variation="subheading-2" class="u-mrgn-top--6">
              {{ mitraWalletConsent.title }}
            </bl-text-av>
            <bl-text-av variation="body-small" class="u-mrgn-top--2">
              {{ mitraWalletConsent.desc }}
            </bl-text-av>
          </div>
          <div class="u-border-top--1--sand-dark u-mrgn-top--4 u-mrgn-bottom--2"></div>
          <bl-checkbox-av
            :is-disabled="consentStatus.recordMitraAccountRegistration.loading"
            :is-checked="Boolean(tncAccepted)"
            value="1"
            @change="onCheckboxChange"
          >
            <div class="u-pad-left--2">
              <bl-text-av tag="span" variation="body-small">
                Saya menyetujui
              </bl-text-av>
              <bl-link-av :href="mitraWalletConsent.tncUrl" target="_blank" is-contrast is-hide-underline>
                Ketentuan Pengguna
              </bl-link-av>
              <bl-text-av tag="span" variation="body-small"> dan mendaftarkan akun saya sebagai Mitra Bukalapak</bl-text-av>
            </div>
          </bl-checkbox-av>
          <bl-button-av
            is-block
            :disabled="!tncAccepted || consentStatus.recordMitraAccountRegistration.loading"
            variation="primary"
            class="u-mrgn-top--4"
            @click="onClickButton"
          >
            {{ mitraWalletConsent.buttonText }}
          </bl-button-av>
        </div>
      </div>
    </bl-modal-mv>

    <verified-phone-modal :is-active.sync="showUnverifiedPhoneModal" data-test-verified-phone-modal style="z-index: 100000;" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { getErrorMessage } from 'shared_js/helpers/error-helper';
import { LOCAL_STORAGE_KEYS } from 'shared_js/constant/local_storage';
import curry from 'shared_js/helpers/curry-helper';
import userApis from 'shared_js/api/public/users';
import BlIllustrationAv from '@bukalapak/bazaar-visual-1.13.0/dist/components/BlIllustrationAv';
import BlModalMv from '@bukalapak/bazaar-dweb-latest/dist/components/BlModalMv';
import BlTextAv from '@bukalapak/bazaar-dweb-latest/dist/components/BlTextAv';
import BlButtonAv from '@bukalapak/bazaar-dweb-latest/dist/components/BlButtonAv';
import BlCheckboxAv from '@bukalapak/bazaar-dweb-latest/dist/components/BlCheckboxAv';
import BlLinkAv from '@bukalapak/bazaar-dweb-latest/dist/components/BlLinkAv';
import BlSpinnerAv from '@bukalapak/bazaar-dweb-latest/dist/components/BlSpinnerAv';
import EmptyState from '../EmptyState';
import VerifiedPhoneModal from '../VerifiedPhoneModal';
import MitraWalletMixin from 'bukasend_mobile/mixins/mitra-wallet';
import PhoneVerificationMixin from 'bukasend_mobile/mixins/phone-verification';

export default {
  name: 'DwebMitraWalletConsentModal',
  components: {
    BlIllustrationAv,
    BlModalMv,
    BlTextAv,
    BlButtonAv,
    BlCheckboxAv,
    BlLinkAv,
    BlSpinnerAv,
    EmptyState,
    VerifiedPhoneModal,
  },
  mixins: [MitraWalletMixin, PhoneVerificationMixin],
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tncAccepted: false,
      showUnverifiedPhoneModal: false,
    };
  },
  computed: {
    ...mapState('user', ['consentStatus', 'consentAgreement', 'fetchMitraAgentStatus']),
    ...mapState('toggle', ['toggleRegisterMitraAgentPhoneVerifiedEnabled']),
    showModal: {
      get() {
        return this.isActive;
      },
      set(value) {
        this.$emit('update:isActive', value);
      }
    },
    isLoading() {
      return this.fetchMitraAgentStatus === null || curry(['consentAgreement', this.mitraWalletConsent.productId, 'loading'], this.consentStatus);
    },
    isError() {
      return this.fetchMitraAgentStatus === false || curry(['consentAgreement', this.mitraWalletConsent.productId, 'error'], this.consentStatus);
    }
  },
  created() {
    this.tncAccepted = Boolean(JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.MITRA_WALLET_CONSENT_TNC_ACCEPTED)));
  },
  methods: {
    ...mapActions('user', ['recordBukasendConsent', 'fetchBukasendConsent']),
    onCheckboxChange()  {
      this.tncAccepted = !this.tncAccepted;
      localStorage.setItem(LOCAL_STORAGE_KEYS.MITRA_WALLET_CONSENT_TNC_ACCEPTED, JSON.stringify(this.tncAccepted));
    },
    async onClickButton() {
      if (this.toggleRegisterMitraAgentPhoneVerifiedEnabled && !this.isPhoneVerified) {
        this.showUnverifiedPhoneModal = true;
        return;
      }

      try {
        await userApis.registerBukasendMitraAgent();
        await this.fetchMitraAgent({ forceReload: true });
        await this.recordBukasendConsent({ agreementId: this.consentAgreement[this.mitraWalletConsent.productId].id });
        this.showModal = this.showMitraWalletConsent;
      } catch (error) {
        this.$bus.$emit('OPEN_SNACKBAR', {
          message: getErrorMessage(error),
          isError: true,
        });
      }
    },
  },
};
</script>

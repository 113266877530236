<template>
  <bl-modal-mv
    title=""
    max-width="600px"
    :is-active.sync="showModal"
    data-test-information-modal
  >
    <div class="u-txt--center">
      <bl-illustration-av
        name="img_medium_emoney_tap"
        alt="Verifikasi nomor hp"
        width="360"
        height="240"
      />
      <bl-text-av is-bolder variation="subheading-2" class="u-mrgn-top--8">
        Verifikasi Nomor Handphone
      </bl-text-av>
      <bl-text-av variation="body-small" class="u-mrgn-top--5">
        Sebelum melakukan aktivasi saldo mitra, harap verifikasi nomor handphone dulu.
      </bl-text-av>
      <bl-button-av
        is-block
        :href="phoneVerificationPageUrl"
        size="large"
        class="u-mrgn-top--8"
        @click="goToPhoneVerificationPage"
        data-test-ok-activate-button
      >
        Verifikasi Sekarang
      </bl-button-av>
    </div>
  </bl-modal-mv>
</template>

<script>
import BlIllustrationAv from '@bukalapak/bazaar-visual-1.13.0/dist/components/BlIllustrationAv';
import BlModalMv from '@bukalapak/bazaar-dweb-latest/dist/components/BlModalMv';
import BlTextAv from '@bukalapak/bazaar-dweb-latest/dist/components/BlTextAv';
import BlButtonAv from '@bukalapak/bazaar-dweb-latest/dist/components/BlButtonAv';
import PhoneVerificationMixin from 'bukasend_mobile/mixins/phone-verification';

export default {
  name: 'VerifiedPhoneModal',
  components: {
    BlIllustrationAv,
    BlModalMv,
    BlTextAv,
    BlButtonAv,
  },
  mixins: [PhoneVerificationMixin],
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showModal: {
      get() {
        return this.isActive;
      },
      set(value) {
        this.$emit('update:isActive', value);
      }
    },
  },
};
</script>
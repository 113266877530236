export default {
  profile: null,
  fetchStatus: null,
  mitraAgent: null,
  fetchMitraAgentStatus: null,
  bukasendMitraAgent: null,
  fetchStatusBukasendMitraAgent: null,
  consentStatus: {
    consentAgreement: {},
    recordMitraAccountRegistration: {
      loading: false,
      error: false,
    },
  },
  consentAgreement: {},
};

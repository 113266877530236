<template>
  <div id="bukasend" :style="containerStyle" :class="containerClass">
    <template v-if="showLoading">
      <div class="u-bg--white u-height-100vh">
        <div :class="['u-loader', { 'u-hidden': isRedirectingToShopifyOAuth }]" data-test-loader />
      </div>
    </template>
    <template v-else>
      <uncompatibility v-if="showUncompatibility" feature="Bukasend" version="1.38" />
      <template v-else-if="showPage">
        <keep-alive><router-view /></keep-alive>
        <client-only>
          <mitra-wallet-consent-wrapper />
          <bl-snackbar-mv
            :active.sync="showSnackbar"
            :message="snackbarMessage"
            :is-error="snackbarError"
          />
        </client-only>
        <add-to-home-screen v-if="!isRedirectingToShopifyOAuth" data-test-add-to-home-screen />
      </template>
      <div v-else class="u-bg--white u-height-100vh">
        <custom-header
          title="BukaSend"
          @left-button-click="back"
        />
        <empty-state
          action-full-width
          :image="emptyState.image"
          :description="emptyState.desc"
          :title="emptyState.title"
          :action="emptyState.action"
          class="u-position-absolute u-center-transform u-width-1 u-pad--4"
          @click-action="requestConfiguration"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import GmapMixin from 'shared_js/mixins/gmap';
import userAgentMixins from 'shared_js/mixins/user-agent';
import HotjarMixin from 'shared_js/mixins/hotjar';
import { setBackgroundColor } from 'shared_js/helpers/color-helper';
import { getUrlLogin } from 'shared_js/helpers/url-helper';
import { isLoggedIn, generateSessionId , getUserId } from 'shared_js/helpers/user-helper';
import { chunkArray } from 'shared_js/helpers/array-helper';
import { platform } from 'shared_js/helpers/user-agent-helper';
import { IS_DEV_MODE, IS_SSR } from 'shared_js/constant/general';
import { NEO_CONFIG_KEYS } from 'shared_js/constant/toggles';
import {
  BUKASEND_CASHBACK_LABEL_DEFAULT,
  BUKASEND_PACKAGE_CONTENT_CATEGORIES_DEFAULT,
  BUKASEND_MISC_CONFIG_DEFAULT,
  BUKASEND_REBOOK_CONFIG_DEFAULT,
  BUKASEND_ONDEMAND_COURIER_SERVICES_DEFAULT,
  BUKASEND_ONGKIR_GOKIL_CONFIG_DEFAULT,
} from 'shared_js/constant/bukasend';
import EmptyState from 'shared_js/components/mobile/EmptyState';
import Uncompatibility from 'shared_js/components/mobile/Uncompatibility';
import aggregateApis from 'shared_js/api/public/aggregate';
import voucherApis from 'shared_js/api/public/vouchers';
import getBukalapakIdentityHeader from 'shared_js/helpers/bl-identity-helper';

import BlSnackbarMv from '@bukalapak/bazaar-mweb/dist/components/BlSnackbarMv';
import ClientOnly from 'vue-client-only';
import curry from 'shared_js/helpers/curry-helper';
import ExperimentMixin from 'bukasend_mobile/mixins/experiment';
import CustomFieldWhitelistUserMixin from './mixins/custom-field-whitelistuser';
import ResizeMixin from './mixins/resize-mixin';
import TrackerMixin from './mixins/tracker';
import CustomHeader from './components/CustomHeader';
import AddToHomeScreen from './components/AddToHomeScreen';
import MitraWalletConsentWrapper from './components/MitraWallet/ConsentWrapper';

const SSR_PAGES = ['courier_landing_page', 'fulfillment_landing_page', 'b2c_landing_page', 'agent_landing_page', 'why_landing_page'];
const NON_LOGIN_SSR_PAGES = ['landing_page', 'delivery_cost_check_page'];

export default {
  name: 'MainApp',
  components: {
    BlSnackbarMv,
    CustomHeader,
    EmptyState,
    Uncompatibility,
    ClientOnly,
    AddToHomeScreen,
    MitraWalletConsentWrapper,
  },
  mixins: [
    GmapMixin,
    userAgentMixins,
    HotjarMixin,
    CustomFieldWhitelistUserMixin,
    ResizeMixin,
    TrackerMixin,
    ExperimentMixin,
  ],
  data() {
    return {
      IS_SSR,
      showSnackbar: false,
      snackbarMessage: '',
      snackbarError: false,
      isLoading: true,
      showUncompatibility: false,
      startTime: new Date().getTime(),
      lastRoute: {},
      requestNeoConfigs: [],
    };
  },
  computed: {
    isLoggedIn,
    getUserId,
    ...mapState('configuration', [
      'configuration',
      'manualBookingCourierServices',
      'courierImageConfig',
      'onboardings',
      'promoConfig',
      'printConfig',
      'entryPoint',
      'visitedRoutes',
      'cashbackConfig',
      'packageContentCategories',
      'grabSLAConfig',
      'miscConfig',
      'rebookConfig',
      'rolePermission',
      'partnerSecret',
      'cancellationConfig',
      'ondemandCourierServices',
      'voucherSuggestions',
      'voucherConfig',
      'memberships',
      'ongkirGokilConfig',
      'kyc',
      'encyclopediaAgentConfig',
      'courierLandingConfig',
      'codConfig',
      'weightTncConfig',
      'benefitsConfig',
      'courierSelectionConfig',
      'courierTags',
    ]),
    ...mapState('toggle', [
      'toggleApp',
      'toggleBukasendDesktop',
      'toggleManualBookingEnabled',
      'toggleHandlingBackDeviceEnabled',
      'toggleOndemandCourierServicesConfigEnabled',
      'togglePermissionsByRoleEnabled',
      'toggleVoucherEnabled',
      'toggleMembershipEnabled',
      'toggleOngkirGokilEnabled',
      'togglePickupGuaranteeNewUser',
      'toggleCodEnabled',
      'toggleKycEnabled',
      'toggleHomeVoucherBannerEnabled',
      'toggleEncyclopediaAgentEnabled',
      'toggleLandingCodBannerEnabled',
      'togglePromotionBudgetEnabled',
      'toggleCostSplitEnabled',
      'toggleMitraWalletEnabled',
      'toggleMitraWalletAnotherEntryPoint',
    ]),
    ...mapState('courier', ['excludePickupFailedCouriers', 'courierOpsTimeConfig', 'backendCourierOpsTimeConfig', 'pickupGuaranteeConfig', 'registeredCouriers']),
    ...mapState('experiment', ['experiments']),
    ...mapGetters('experiment', [
      'bukasendCleanupCTAExperimentKey',
    ]),
    ...mapState('transactions', ['isNewUser']),
    ...mapState('promoBudget', ['promoBudgetInfo']),
    ...mapState('user', ['mitraAgent', 'bukasendMitraAgent']),
    ...mapGetters('configuration', ['isRolePartner']),
    emptyState() {
      const emptyState = {
        image: `${process.env.S4_IMAGE_URL}/m-ast/web-drawable/drawable-hdpi/img_medium_satelit.png`,
        title: 'Tidak Ada Koneksi',
        desc: 'Pastikan koneksi internet tidak bermasalah dan silakan muat ulang.',
        action: 'Muat Ulang'
      };

      if (this.configuration) {
        const defaultMessage = 'Layanan BukaSend untuk akunmu sementara dinonaktifkan. Hubungi Bukalapak untuk info lebih lanjut.';

        emptyState.image = `${process.env.S4_IMAGE_URL}/m-ast/web-drawable/drawable-hdpi/ic_verification_unmatch.png`;
        emptyState.title = 'Ada Kendala';
        emptyState.desc = this.configuration.error_message || defaultMessage;
        emptyState.action = null;
      }

      return emptyState;
    },
    isTrackingPage() {
      return this.$route.name === 'tracking_page';
    },
    isAllowedPages() {
      return [
        'print_invoice_page',
        'print_resi_page',
        'landing_page',
        'tracking_page',
        'delivery_cost_check_page',
        'promo_detail_page',
        'voucher_detail_page',
        'shopify_extension_landing',
        'woocommerce_extension_landing',
        'courier_landing_page',
        'fulfillment_landing_page',
        'b2c_landing_page',
        'agent_landing_page',
        'why_landing_page',
        'kyc_page',
      ].includes(this.$route.name);
    },
    showPage() {
      return this.isAllowedPages ||
        (this.configuration && this.configuration.availability) ||
        (['form_page', 'summary_page'].includes(this.$route.name) && !this.isLoggedIn);
    },
    isPrintPage() {
      return ['print_invoice_page', 'print_resi_page'].includes(this.$route.name);
    },
    // need to put as inline-style since loading css class has delay and the screen width will flicker
    containerStyle() {
      return this.isRedirectingToShopifyOAuth && this.isDesktop ? 'max-width: none' : '';
    },
    containerClass() {
      return {
        'bukasend-desktop-mode': this.isDesktopMode || this.isCourierLandingPage,
      };
    },
    isCourierLandingPage() {
      return this.$route.name === 'courier_landing_page';
    },
    courierLandingNeoId() {
      return this.isCourierLandingPage && this.$route.params.courierCode ? NEO_CONFIG_KEYS.BUKASEND_COURIER_LANDING_CONFIG.replace('%COURIER_CODE%', this.$route.params.courierCode) : '';
    },
    showLoading() {
      const isRouteSSR = SSR_PAGES.includes(this.$route.name);
      const isLoggedOutSSR = !this.isLoggedIn && NON_LOGIN_SSR_PAGES.includes(this.$route.name);
      const isSSRPage = curry(['fragmentData', 'ssrEnabled'], this.$root) && (isLoggedOutSSR || isRouteSSR);
 
      return this.isLoading && !this.isPrintPage && !(IS_SSR && isSSRPage);
    },
    isRedirectingToShopifyOAuth() {
      // need to hide bukasend loading during shopify oauth redirection
      const { name, query } = this.$route || {};
      return name === 'shopify_extension_landing' && !query.code;
    },
  },
  watch: {
    '$route.name': {
      handler(to, from) {
        this.lastRoute = {
          from,
          to,
        };
        if (this.isLoading) {
          return;
        }
        this.watchRouteName(to, from);
      },
    },
    isLoading(val) {
      if (!val) {
        const { from, to } = this.lastRoute;
        this.watchRouteName(to, from);
      }
    }
  },
  created() {
    this.$router.afterEach((to, from) => {
      if (!!from.name && to.name && from.name !== to.name && !IS_SSR) {
        // don't send leave page tracker if current page is landing page
        // and new landing or new home page is active
        if (from.name === 'landing_page' || from.name === 'home_page') {
          return;
        }
        const currentTime = new Date().getTime();
        const visitDuration = parseInt((currentTime - this.startTime) / 1000, 10);
        this.trackBukasendLeavePage(from.name, visitDuration);

        this.startTime = currentTime;
      }
    });
  },
  mounted() {
    if (!this.entryPoint) {
      this.SET_ENTRY_POINT(this.$route.query.from || '');
    }
    this.init();
  },
  methods: {
    ...mapMutations('configuration', [
      'SET_SESSION_ID',
      'SET_COURIER_IMAGE_CONFIG',
      'SET_ONBOARDINGS',
      'SET_MANUAL_BOOKING_COURIER_SERVICES',
      'SET_PROMO_CONFIG',
      'SET_PRINT_CONFIG',
      'SET_INITIALIZED',
      'SET_ENTRY_POINT',
      'SET_CASHBACK_CONFIG',
      'SET_PACKAGE_CONTENT_CATEGORIES',
      'SET_GRAB_SLA_CONFIG',
      'SET_MISC_CONFIG',
      'SET_REBOOK_CONFIG',
      'SET_ROLE_PERMISSION',
      'SET_CANCELLATION_CONFIG',
      'SET_ONDEMAND_COURIER_SERVICES',
      'SET_VOUCHER_SUGGESTIONS',
      'SET_VOUCHER_CONFIG',
      'SET_MEMBERSHIPS',
      'SET_ONGKIR_GOKIL_CONFIG',
      'SET_KYC_CONFIG',
      'SET_ENCYCLOPEDIA_AGENT_CONFIG',
      'SET_COURIER_LANDING_CONFIG',
      'SET_COD_CONFIG',
      'SET_WEIGHT_TNC_CONFIG',
      'SET_BENEFITS_CONFIG',
      'SET_COURIER_SELECTION_CONFIG',
      'SET_COURIER_TAGS',
    ]),
    ...mapMutations('configuration', {
      SET_CONFIGURATION_STATE_VALUE: 'SET_STATE_VALUE',
    }),
    ...mapMutations('transactions', ['SET_IS_NEW_USER']),
    ...mapMutations('courier', [
      'SET_EXCLUDE_PICKUP_FAILED_COURIERS',
      'SET_COURIER_OPS_TIME_CONFIG',
      'SET_BACKEND_COURIER_OPS_TIME_CONFIG',
      'SET_REGISTERED_COURIERS',
      'SET_PICKUP_GUARANTEE_CONFIG',
    ]),
    ...mapActions('configuration', [
      'fetchConfiguration',
    ]),
    ...mapActions('toggle', [
      'fetchToggles',
    ]),
    ...mapActions('promoBudget', [
      'fetchPromoBudgetInfo',
    ]),
    ...mapActions('courierTags', [
      'setCourierTags',
    ]),
    ...mapActions('user', [
      'fetchMitraAgent',
      'fetchBukasendMitraAgent',
    ]),
    watchRouteName(to, from) {
      if (IS_SSR || !to) {
        return;
      }
      // save referrer for tracker params
      this.$router.referrer = from ? this.$router.resolve({ name: from }) : {};


      const nonLoggedInPages = [
        'landing_page',
        'tracking_page',
        'delivery_cost_check_page',
        'leaderboard_page',
        'promo_detail_page',
        'voucher_detail_page',
        'shopify_extension_landing',
        'woocommerce_extension_landing',
        'courier_landing_page',
        'form_page',
        'summary_page',
        'fulfillment_landing_page',
        'b2c_landing_page',
        'agent_landing_page',
        'why_landing_page',
        'kyc_page',
      ];

      if (!this.isLoggedIn && !nonLoggedInPages.includes(to)) {
        window.location.href = getUrlLogin(window.location.pathname);
      } else if (this.toggleHandlingBackDeviceEnabled
        && platform() !== 'desktop'
        && ['home_page', 'delivery_cost_check_page', 'form_page', 'summary_page'].includes(from)
        && ['landing_page', 'home_page'].includes(to)
        && this.visitedRoutes.includes(to)) {
        // this is a workaround to handle the issue that requires the user
        // to click back button twice to exit / back from landing page
        this.$router.go(-1);
      }

      if (this.isLoggedIn && !this.hidePromoIfPartner && this.toggleCostSplitEnabled && ['landing_page', 'home_page', 'form_page', 'summary_page'].includes(to)) {
        voucherApis.claimAllCoupons();
      }
    },
    init() {
      setBackgroundColor();
      this.SET_SESSION_ID(generateSessionId());

      if (this.isBLMitra && this.BLMitraVersion() < 1038000 && !IS_DEV_MODE) {
        this.showUncompatibility = true;
        this.isLoading = false;
        return;
      }

      this.eventBusInit();
      this.requestConfiguration();
      if (!IS_SSR) {
        this.$bus.$emit('GOOGLE_MAP_LOAD');
      }
    },
    back() {
      this.$router.back();
    },
    eventBusInit() {
      this.$bus.$on('OPEN_SNACKBAR', payload => {
        this.snackbarMessage = payload.message;
        this.snackbarError = payload.isError || false;
        this.showSnackbar = true;
      });

      this.onGoogleMapLoad();
    },
    async callAggregate() {
      await this.fetchToggles();

      let requestNeoConfigs = [
        {
          id: NEO_CONFIG_KEYS.BUKASEND_AVAILABLE_COURIER_CONFIG,
          requestFetch: this.courierImageConfig === null,
        },
        {
          id: NEO_CONFIG_KEYS.BUKASEND_PROMO_CONFIG,
          requestFetch: this.promoConfig === null,
        },
        {
          id: NEO_CONFIG_KEYS.BUKASEND_CASHBACK_LABEL,
          requestFetch: this.cashbackConfig === null,
        },
        {
          id: NEO_CONFIG_KEYS.BUKASEND_MISC_CONFIG,
          requestFetch: this.miscConfig === null,
        },
        {
          id: NEO_CONFIG_KEYS.BUKASEND_VOUCHER_CONFIG,
          requestFetch: this.toggleVoucherEnabled && this.voucherConfig === null,
        },
        {
          id: NEO_CONFIG_KEYS.BUKASEND_MANUAL_BOOKING_COURIER_SERVICES,
          requestFetch: this.toggleManualBookingEnabled && this.manualBookingCourierServices === null,
        },
        {
          id: NEO_CONFIG_KEYS.BUKASEND_PICKUP_GUARANTEE_CONFIG,
          requestFetch: this.togglePickupGuaranteeNewUser && this.pickupGuaranteeConfig === null,
        },
        {
          id: NEO_CONFIG_KEYS.BUKASEND_ONGKIR_GOKIL_CONFIG,
          requestFetch: this.toggleOngkirGokilEnabled && this.ongkirGokilConfig === null,
        },
        {
          id: NEO_CONFIG_KEYS.BUKASEND_PACKAGE_CONTENT_CATEGORIES,
          requestFetch: this.packageContentCategories === null,
        },
        {
          id: NEO_CONFIG_KEYS.BUKASEND_ENCYCLOPEDIA_AGENT_CONFIG,
          requestFetch: this.toggleEncyclopediaAgentEnabled && this.encyclopediaAgentConfig === null,
        },
        {
          id: NEO_CONFIG_KEYS.BUKASEND_COD_CONFIG,
          requestFetch: this.toggleLandingCodBannerEnabled && this.codConfig === null,
        },
        {
          id: NEO_CONFIG_KEYS.BUKASEND_WEIGHT_TNC_CONFIG,
          requestFetch: this.weightTncConfig === null,
        },
        {
          id: NEO_CONFIG_KEYS.BUKASEND_BENEFITS_CONFIG,
          requestFetch: this.benefitsConfig === null,
        },
        {
          id: NEO_CONFIG_KEYS.BUKASEND_COURIER_SELECTION_CONFIG,
          requestFetch: this.courierSelectionConfig === null,
        },
      ];

      if (this.courierLandingNeoId) {
        requestNeoConfigs.push({
          id: this.courierLandingNeoId,
          requestFetch: this.courierLandingConfig === null,
        });
      }

      let requests = [
        {
          key: 'setRolePermission',
          path: `/open-shipments/roles`,
          requestFetch: this.togglePermissionsByRoleEnabled && this.rolePermission === null,
        },
        {
          key: 'setVoucherSuggestions',
          path: `/promos/vouchers/codes?category=product&type=buka-pengiriman&offset=0&limit=10`,
          requestFetch: this.toggleVoucherEnabled && this.voucherSuggestions === null,
        },
        {
          key: 'setMemberships',
          path: '/open-shipments/memberships',
          requestFetch: this.memberships === null,
        },
        {
          key: 'setKyc',
          path: '/open-shipments/users/kycs',
          requestFetch: this.toggleKycEnabled && this.kyc === null,
        },
        {
          key: 'fetchPromoBudgetInfo',
          path: `/open-shipments/promotions/availability`,
          requestFetch: this.togglePromotionBudgetEnabled && this.promoBudgetInfo === null,
        },
        {
          key: 'setCourierTags',
          path: '/open-shipments/couriers/tags',
          requestFetch: this.courierTags === null,
        },
      ];

      if (this.isLoggedIn) {
        requests = requests.concat([
          {
            key: 'fetchConfiguration',
            path: `/open-shipments/configurations`,
            requestFetch: this.configuration === null,
          },
          {
            key: 'setOnboardings',
            path: `/open-shipments/onboardings`,
            requestFetch: this.isBLMitra && this.onboardings === null,
          },
          {
            key: 'setRegisteredCouriers',
            path: `/couriers`,
            requestFetch: platform() === 'desktop' && this.registeredCouriers === null,
          },
          // This endpoint currently is not available because service is not migrated to gcp
          // This endpoint will be used to show menu "Integrasi API" on desktop
          // We comment this code because we got error when call aggregate and service itself is not exist
          // {
          //   key: 'setPartnerSecret',
          //   path: `/fulfillments/account-inventories`,
          //   requestFetch: this.partnerSecret === null,
          // },
          {
            key: 'fetchMitraAgent',
            path: `/agents/me`,
            requestFetch: !this.isRolePartner && (this.toggleMitraWalletEnabled || this.toggleMitraWalletAnotherEntryPoint) && this.mitraAgent === null,
          },
          {
            key: 'fetchBukasendMitraAgent',
            path: `/open-shipments/users/mitra-agents`,
            requestFetch: !this.isRolePartner && (this.toggleMitraWalletEnabled || this.toggleMitraWalletAnotherEntryPoint) && this.bukasendMitraAgent === null,
          },
        ]);

        requestNeoConfigs = requestNeoConfigs.concat([
          {
            id: NEO_CONFIG_KEYS.BUKASEND_CUSTOM_FIELD_WHITELIST_USERS,
            requestFetch: this.customFieldWhitelistUsers === null,
          },
          {
            id: NEO_CONFIG_KEYS.BUKASEND_PRINT_CONFIG,
            requestFetch: this.printConfig === null,
          },
          {
            id: NEO_CONFIG_KEYS.BUKASEND_EXCLUDE_PICKUP_FAILED_COURIERS,
            requestFetch: this.excludePickupFailedCouriers === null,
          },
          {
            id: NEO_CONFIG_KEYS.BUKASEND_GRAB_SLA_CONFIG,
            requestFetch: this.grabSLAConfig === null,
          },
          {
            id: NEO_CONFIG_KEYS.BUKASEND_REBOOK_CONFIG,
            requestFetch: this.rebookConfig === null,
          },
          {
            id: NEO_CONFIG_KEYS.BUKASEND_COURIER_OPS_TIME_CONFIG,
            requestFetch: this.courierOpsTimeConfig === null,
          },
          {
            id: NEO_CONFIG_KEYS.BUKASEND_CANCELLATION_CONFIG,
            requestFetch: this.cancellationConfig === null,
          },
          {
            id: NEO_CONFIG_KEYS.BUKASEND_ONDEMAND_COURIER_SERVICES,
            requestFetch: this.toggleOndemandCourierServicesConfigEnabled && this.ondemandCourierServices === null,
          },
        ]);
      }
      this.requestNeoConfigs = requestNeoConfigs;
      const filteredNeoConfigs = requestNeoConfigs.filter(r => !!r.requestFetch).map(r => r.id);
      if (filteredNeoConfigs.length > 0) {
        const pagedNeoConfigs = chunkArray(filteredNeoConfigs, 10);
        pagedNeoConfigs.forEach((configIds, index) => {
          requests.push({
            key: `setNeoConfigs${index > 0 ? `-page-${index}` : ''}`,
            path: `/_exclusive/neo/configs`,
            method: 'POST',
            body: {
              id: configIds,
            },
            requestFetch: true,
          });
        });
      }

      const aggregates = requests.filter(req => !!req.requestFetch).reduce((result, req) => {
        const { key, path, body, method = 'GET' } = req;
        const params = {
          path,
          method,
        };
        if (body) {
          params.body = body;
        }
        return {
          ...result,
          [key]: params,
        };
      }, {});

      if (Object.keys(aggregates).length > 0) {
        const headers = getBukalapakIdentityHeader()

        await aggregateApis.aggregate(aggregates, { headers }).then(async response => {
          const { data, error, meta } = response;

          const neoConfigResult = {};
          Object.keys(aggregates).filter(k => k.replace(/-page-\d+/, '') === 'setNeoConfigs').forEach(key => {
            neoConfigResult.meta = Object.assign(neoConfigResult.meta || {}, meta[key]);
            if (data[key]) {
              neoConfigResult.data = (neoConfigResult.data || []).concat(data[key]);
            } else {
              neoConfigResult.errors = (neoConfigResult.errors || []).concat(error[key]);
            }
          });
          if (Object.keys(neoConfigResult).length) {
            await this.setNeoConfigs({ apiResult: neoConfigResult });
          }
          Object.keys(aggregates).filter(k => k.replace(/-page-\d+/, '') !== 'setNeoConfigs').forEach(async key => {
            const apiResult = {
              meta: meta[key]
            };

            if (data[key]) {
              apiResult.data = data[key];
            } else {
              apiResult.errors = error[key];
            }

            await this[key]({ apiResult });
          });
        });
      }
    },
    async requestConfiguration() {
      let additionalRequests = {};
      this.isLoading = true;

      if (!this.isPrintPage) {
        if (this.isLoggedIn && this.isNewUser === null) {
          additionalRequests = {
            setFirstTransactions: {
              path: '/open-shipments/transactions?limit=1&offset=0',
              method: 'GET',
              callback: this.setFirstTransactions,
            },
          };
        }
      }

      await this.fetchToggles(additionalRequests);

      if (!this.toggleApp && !IS_SSR) {
        // eslint-disable-next-line no-return-assign
        window.location.href = window.location.origin;
        return;
      }

      if (!this.isPrintPage) {
        await this.callAggregate();
        this.fetchBackendOpsTimeCouriers();
      }

      this.watchRouteName(this.$route.name, '');

      this.$nextTick(() => {
        setTimeout(() => {
          this.isLoading = false;
          this.SET_INITIALIZED();
          this.loadHotjarUserAttributes();
        }, 300);
      });
    },
    setFirstTransactions({ apiResult }) {
      const { data } = apiResult;
      this.SET_IS_NEW_USER(data && data.length === 0);
    },
    setAvailableCourierConfig({ apiResult }) {
      const { data } = apiResult;
      const config = data.find(d => d.id === NEO_CONFIG_KEYS.BUKASEND_AVAILABLE_COURIER_CONFIG);
      if (config) {
        this.SET_COURIER_IMAGE_CONFIG(config.data);
      }
    },
    setOnboardings({ apiResult }) {
      const { data } = apiResult;
      this.SET_ONBOARDINGS(data || null);
    },
    setWeightTncConfig({ apiResult }) {
      const { data } = apiResult;
      const config = data.find(d => d.id === NEO_CONFIG_KEYS.BUKASEND_WEIGHT_TNC_CONFIG);
      this.SET_WEIGHT_TNC_CONFIG(config ? config.data : null);
    },
    setCourierSelectionConfig({ apiResult }) {
      const { data } = apiResult;
      const config = data.find(d => d.id === NEO_CONFIG_KEYS.BUKASEND_COURIER_SELECTION_CONFIG);
      this.SET_COURIER_SELECTION_CONFIG(config ? config.data : null);
    },
    setManualBookingCourierServices({ apiResult }) {
      const { data } = apiResult;
      const config = data.find(d => d.id === NEO_CONFIG_KEYS.BUKASEND_MANUAL_BOOKING_COURIER_SERVICES);
      if (config && config.data && config.data.courier_services) {
        this.SET_MANUAL_BOOKING_COURIER_SERVICES(config.data.courier_services);
      }
    },
    setPromoConfig({ apiResult }) {
      const { data } = apiResult;
      const config = data.find(d => d.id === NEO_CONFIG_KEYS.BUKASEND_PROMO_CONFIG);
      this.SET_PROMO_CONFIG(config ? config.data : null);
    },
    setPrintConfig({ apiResult }) {
      const { data } = apiResult;
      const config = data.find(d => d.id === NEO_CONFIG_KEYS.BUKASEND_PRINT_CONFIG);
      this.SET_PRINT_CONFIG(config ? config.data : null);
    },
    setExcludePickupFailedCouriers({ apiResult }) {
      const { data } = apiResult;
      const config = data.find(d => d.id === NEO_CONFIG_KEYS.BUKASEND_EXCLUDE_PICKUP_FAILED_COURIERS);
      this.SET_EXCLUDE_PICKUP_FAILED_COURIERS(config ? config.data : null);
    },
    setCashbackConfig({ apiResult }) {
      const { data } = apiResult;
      const config = data.find(d => d.id === NEO_CONFIG_KEYS.BUKASEND_CASHBACK_LABEL);
      this.SET_CASHBACK_CONFIG(config ? config.data : BUKASEND_CASHBACK_LABEL_DEFAULT);
    },
    setPackageContentCategories({ apiResult }) {
      const { data } = apiResult;
      const config = data.find(d => d.id === NEO_CONFIG_KEYS.BUKASEND_PACKAGE_CONTENT_CATEGORIES);
      if (config && config.data && config.data.categories) {
        this.SET_PACKAGE_CONTENT_CATEGORIES(config.data.categories);
      } else {
        this.SET_PACKAGE_CONTENT_CATEGORIES(BUKASEND_PACKAGE_CONTENT_CATEGORIES_DEFAULT);
      }
    },
    fetchBackendOpsTimeCouriers() {
      if (this.courierOpsTimeConfig && (!this.backendCourierOpsTimeConfig || Object.keys(this.backendCourierOpsTimeConfig).length === 0)) {
        const { backendOpsTimeCouriers = [] } = this.courierOpsTimeConfig || {};
        const aggregates = backendOpsTimeCouriers.reduce((result, courierServiceName) => {
          const params = {
            path: `/open-shipments/couriers/settings/${courierServiceName}`,
            method: 'GET',
          };
          return {
            ...result,
            [courierServiceName]: params,
          };
        }, {});

        aggregateApis.aggregate(aggregates).then(response => {
          this.SET_BACKEND_COURIER_OPS_TIME_CONFIG(response.data);
        });
      }
    },
    setCourierOpsTimeConfig({ apiResult }) {
      if (this.courierOpsTimeConfig === null) {
        const defaultConfig = {
          operationalTime: {
            'Grab Instant': {
              hour_end: 22,
              hour_end_jabodetabek: null,
              hour_start: 6,
              hour_start_jabodetabek: null,
            },
            'Grab Same Day': {
              hour_end: 16,
              hour_end_jabodetabek: null,
              hour_start: 9,
              hour_start_jabodetabek: null,
            },
            'Rocket Delivery': {
              hour_end: 22,
              hour_end_jabodetabek: null,
              hour_start: 6,
              hour_start_jabodetabek: null,
            },
          },
          warningTemplate: 'Kamu hanya bisa memesan driver pada layanan ini dari jam {{opstime}}.',
          backendOpsTimeCouriers: ['Grab Instant', 'Grab Same Day', 'Rocket Delivery'],
        };
        const { data } = apiResult;
        const config = data.find(d => d.id === NEO_CONFIG_KEYS.BUKASEND_COURIER_OPS_TIME_CONFIG);
        this.SET_COURIER_OPS_TIME_CONFIG(config ? config.data : defaultConfig);
      }
    },
    setGrabSLAConfig({ apiResult }) {
      const { data } = apiResult;
      const config = data.find(d => d.id === NEO_CONFIG_KEYS.BUKASEND_GRAB_SLA_CONFIG);
      this.SET_GRAB_SLA_CONFIG(config ? config.data : null);
    },
    setMiscConfig({ apiResult }) {
      const { data } = apiResult;
      const config = data.find(d => d.id === NEO_CONFIG_KEYS.BUKASEND_MISC_CONFIG);
      this.SET_MISC_CONFIG(config ? config.data : BUKASEND_MISC_CONFIG_DEFAULT);
    },
    setRebookConfig({ apiResult }) {
      const { data } = apiResult;
      const config = data.find(d => d.id === NEO_CONFIG_KEYS.BUKASEND_REBOOK_CONFIG);
      this.SET_REBOOK_CONFIG(config ? config.data : BUKASEND_REBOOK_CONFIG_DEFAULT);
    },
    setRolePermission({ apiResult }) {
      const { data } = apiResult;
      this.SET_ROLE_PERMISSION(data || null);
    },
    setPartnerSecret({ apiResult }) {
      const { data } = apiResult;
      this.SET_CONFIGURATION_STATE_VALUE({
        stateName: 'partnerSecret',
        value: data || null,
      });
    },
    setMemberships({ apiResult }) {
      const { data } = apiResult;
      this.SET_MEMBERSHIPS(data || null);
    },
    setKyc({ apiResult }) {
      const { data } = apiResult;
      this.SET_KYC_CONFIG(data || null);
    },
    setCancellationConfig({ apiResult }) {
      const { data } = apiResult;
      const config = data.find(d => d.id === NEO_CONFIG_KEYS.BUKASEND_CANCELLATION_CONFIG);
      this.SET_CANCELLATION_CONFIG(config ? config.data : null);
    },
    setOndemandCourierServices({ apiResult }) {
      const { data } = apiResult;
      const config = data.find(d => d.id === NEO_CONFIG_KEYS.BUKASEND_ONDEMAND_COURIER_SERVICES);
      if (config && config.data && config.data.courier_services) {
        this.SET_ONDEMAND_COURIER_SERVICES(config.data.courier_services);
      } else {
        this.SET_ONDEMAND_COURIER_SERVICES(BUKASEND_ONDEMAND_COURIER_SERVICES_DEFAULT);
      }
    },
    setVoucherSuggestions({ apiResult }) {
      const { data } = apiResult;
      this.SET_VOUCHER_SUGGESTIONS(data || null);
    },
    setVoucherConfig({ apiResult }) {
      const { data } = apiResult;
      const defaultConfig = {
        filter: {
          default: [],
        }
      };
      const config = data.find(d => d.id === NEO_CONFIG_KEYS.BUKASEND_VOUCHER_CONFIG);
      this.SET_VOUCHER_CONFIG(config ? config.data : defaultConfig);
    },
    setOngkirGokilConfig({ apiResult }) {
      const { data } = apiResult;
      const config = data.find(d => d.id === NEO_CONFIG_KEYS.BUKASEND_ONGKIR_GOKIL_CONFIG);
      this.SET_ONGKIR_GOKIL_CONFIG(config ? config.data : BUKASEND_ONGKIR_GOKIL_CONFIG_DEFAULT);
    },
    setRegisteredCouriers({ apiResult }) {
      this.SET_REGISTERED_COURIERS(apiResult.data || []);
    },
    setPickupGuaranteeConfig({ apiResult }) {
      const { data } = apiResult;
      const config = apiResult.data ? data.find(d => d.id === NEO_CONFIG_KEYS.BUKASEND_PICKUP_GUARANTEE_CONFIG) : null;
      this.SET_PICKUP_GUARANTEE_CONFIG(config ? config.data : null);
    },
    setEncyclopediaAgentConfig({ apiResult }) {
      const { data } = apiResult;
      const config = apiResult.data ? data.find(d => d.id === NEO_CONFIG_KEYS.BUKASEND_ENCYCLOPEDIA_AGENT_CONFIG) : null;
      this.SET_ENCYCLOPEDIA_AGENT_CONFIG(config ? config.data : null);
    },
    setCodConfig({ apiResult }) {
      const { data } = apiResult;
      const config = apiResult.data ? data.find(d => d.id === NEO_CONFIG_KEYS.BUKASEND_COD_CONFIG) : null;
      this.SET_COD_CONFIG(config ? config.data : null);
    },
    setBenefitsConfig({ apiResult }) {
      const { data } = apiResult;
      const config = apiResult.data ? data.find(d => d.id === NEO_CONFIG_KEYS.BUKASEND_BENEFITS_CONFIG) : null;
      this.SET_BENEFITS_CONFIG(config ? config.data : null);
    },
    setCourierLandingConfig({ apiResult }) {
      const { data } = apiResult;
      const config = data.find(d => d.id === this.courierLandingNeoId);
      if (config) {
        this.SET_COURIER_LANDING_CONFIG(config.data);
      }
    },
    setCourierTags({ apiResult }) {
      const { data } = apiResult;
      this.SET_COURIER_TAGS(data || null);
    },
    setNeoConfigs({ apiResult }) {
      const callbacks = {
        [NEO_CONFIG_KEYS.BUKASEND_AVAILABLE_COURIER_CONFIG]: 'setAvailableCourierConfig',
        [NEO_CONFIG_KEYS.BUKASEND_MANUAL_BOOKING_COURIER_SERVICES]: 'setManualBookingCourierServices',
        [NEO_CONFIG_KEYS.BUKASEND_PROMO_CONFIG]: 'setPromoConfig',
        [NEO_CONFIG_KEYS.BUKASEND_CUSTOM_FIELD_WHITELIST_USERS]: 'setCustomFieldWhitelistUsers',
        [NEO_CONFIG_KEYS.BUKASEND_PRINT_CONFIG]: 'setPrintConfig',
        [NEO_CONFIG_KEYS.BUKASEND_EXCLUDE_PICKUP_FAILED_COURIERS]: 'setExcludePickupFailedCouriers',
        [NEO_CONFIG_KEYS.BUKASEND_CASHBACK_LABEL]: 'setCashbackConfig',
        [NEO_CONFIG_KEYS.BUKASEND_PACKAGE_CONTENT_CATEGORIES]: 'setPackageContentCategories',
        [NEO_CONFIG_KEYS.BUKASEND_GRAB_SLA_CONFIG]: 'setGrabSLAConfig',
        [NEO_CONFIG_KEYS.BUKASEND_MISC_CONFIG]: 'setMiscConfig',
        [NEO_CONFIG_KEYS.BUKASEND_REBOOK_CONFIG]: 'setRebookConfig',
        [NEO_CONFIG_KEYS.BUKASEND_COURIER_OPS_TIME_CONFIG]: 'setCourierOpsTimeConfig',
        [NEO_CONFIG_KEYS.BUKASEND_CANCELLATION_CONFIG]: 'setCancellationConfig',
        [NEO_CONFIG_KEYS.BUKASEND_ONDEMAND_COURIER_SERVICES]: 'setOndemandCourierServices',
        [NEO_CONFIG_KEYS.BUKASEND_VOUCHER_CONFIG]: 'setVoucherConfig',
        [NEO_CONFIG_KEYS.BUKASEND_ONGKIR_GOKIL_CONFIG]: 'setOngkirGokilConfig',
        [NEO_CONFIG_KEYS.BUKASEND_PICKUP_GUARANTEE_CONFIG]: 'setPickupGuaranteeConfig',
        [NEO_CONFIG_KEYS.BUKASEND_ENCYCLOPEDIA_AGENT_CONFIG]: 'setEncyclopediaAgentConfig',
        [NEO_CONFIG_KEYS.BUKASEND_COD_CONFIG]: 'setCodConfig',
        [NEO_CONFIG_KEYS.BUKASEND_WEIGHT_TNC_CONFIG]: 'setWeightTncConfig',
        [NEO_CONFIG_KEYS.BUKASEND_BENEFITS_CONFIG]: 'setBenefitsConfig',
        [NEO_CONFIG_KEYS.BUKASEND_COURIER_SELECTION_CONFIG]: 'setCourierSelectionConfig',
        [this.courierLandingNeoId]: 'setCourierLandingConfig',
      };
      Object.keys(callbacks).filter(key => !!key).forEach(key => {
        const neoConfig = this.requestNeoConfigs.find(config => config.id === key);
        if (neoConfig && neoConfig.requestFetch) {
          this[callbacks[key]]({apiResult});
        }
      });
    }
  },
};
</script>

import { mapState } from 'vuex';
import { isLoggedIn } from 'shared_js/helpers/user-helper';
import { getMarketplaceHost } from 'shared_js/helpers/url-helper';
import curry from 'shared_js/helpers/curry-helper';
import UserAgentMixin from 'shared_js/mixins/user-agent';

export default {
  mixins: [UserAgentMixin],
  computed: {
    isLoggedIn,
    ...mapState('toggle', ['togglePhoneVerificationEnabled']),
    phoneNumber() {
      return curry(['fragmentData', 'user', 'phone'], this.$root);
    },
    isPhoneVerified() {
      const confirmedPhone = curry(['fragmentData', 'user', 'confirmed_phone'], this.$root);
      const phoneConfirmed =
        process.env.NODE_ENV !== 'production' ? curry(['fragmentData', 'user', 'phone_confirmed'], this.$root) : false;

      return Boolean(confirmedPhone || phoneConfirmed);
    },
    showWarningUnverifiedPhone() {
      return Boolean(this.isLoggedIn && this.togglePhoneVerificationEnabled && !this.isPhoneVerified);
    },
    phoneVerificationPageUrl() {
      const backUrl = encodeURIComponent(window.location.href);
      const path =
        this.platform === 'desktop' && this.phoneNumber && !this.isPhoneVerified
          ? '/account_settings'
          : '/phone_settings';

      return `${getMarketplaceHost()}${path}?comeback=${backUrl}`;
    },
  },
  methods: {
    goToPhoneVerificationPage() {
      window.location.href = this.phoneVerificationPageUrl;
    },
  },
};

<template>
  <bl-sheet-mv
    auto-height
    :active.sync="showUnverifiedPhoneValidationModal"
    class="bl-sheet--no-handler bl-sheet--navbar-no-border-bottom"
  >
    <div slot="content" class="u-pad--4 u-mrgn-bottom--2 u-txt--center">
      <div class="c-phone-verification-modal__wrapper">
        <bl-illustration-av
          name="img_medium_emoney_tap"
          alt="Verifikasi nomor hp"
          class="u-mrgn-bottom--4"
        />
      </div>
      <bl-text-av class="u-mrgn-bottom--4" variation="subheading-18">
        {{ title || miscConfig.kyc.verifications_copy.phone.title }}
      </bl-text-av>
      <bl-text-av class="u-mrgn-bottom--4">
        {{ desc || miscConfig.kyc.verifications_copy.phone.desc }}
      </bl-text-av>
      <bl-button-av
        variation="primary"
        :href="phoneVerificationPageUrl"
        fullwidth
        @click="goToPhoneVerificationPage"
      >
        Verifikasi Sekarang
      </bl-button-av>
    </div>
  </bl-sheet-mv>
</template>
<script>
import { mapState } from 'vuex';
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';
import BlSheetMv from '@bukalapak/bazaar-mweb/dist/components/BlSheetMv';
import BlButtonAv from '@bukalapak/bazaar-mweb/dist/components/BlButtonAv';
import BlIllustrationAv from '@bukalapak/bazaar-visual-1.13.0/dist/components/BlIllustrationAv';
import PhoneVerificationMixin from '../../mixins/phone-verification';

export default {
  name: 'KycVerifiedPhoneModal',
  components: {
    BlTextAv,
    BlSheetMv,
    BlButtonAv,
    BlIllustrationAv,
  },
  mixins: [PhoneVerificationMixin],
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('configuration', [
      'miscConfig',
    ]),
    showUnverifiedPhoneValidationModal: {
      get() {
        return this.isActive;
      },
      set(value) {
        this.$emit('update:isActive', value);
      },
    },
  },
};
</script>
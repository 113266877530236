<template>
  <div :class="{ 'bukasend-bazaar-dweb': isDesktop }">
    <dweb-mitra-wallet-consent-modal v-if="isDesktop" :is-active.sync="isShowMitraWalletConsentModal" />
    <mweb-mitra-wallet-consent-modal v-else :is-active.sync="isShowMitraWalletConsentModal" />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { isDesktop } from 'shared_js/helpers/user-agent-helper';
import { isLoggedIn } from 'shared_js/helpers/user-helper';
import MwebMitraWalletConsentModal from 'bukasend_mobile/components/MitraWallet/ConsentModal';
import DwebMitraWalletConsentModal from 'bukasend_desktop/components/MitraWallet/ConsentModal';
import MitraWalletMixin from 'bukasend_mobile/mixins/mitra-wallet';

export default {
  name: 'MitraWalletConsentWrapper',
  components: {
    MwebMitraWalletConsentModal,
    DwebMitraWalletConsentModal,
  },
  mixins: [MitraWalletMixin],
  data() {
    return {
      isShowMitraWalletConsentModal: false,
    };
  },
  computed: {
    isLoggedIn,
    isDesktop,
    ...mapState('transactions', ['isNewUser']),
  },
  watch: {
    isShowMitraWalletConsentModal: {
      handler(value) {
        if (this.showMitraWalletConsent && !value) {
          this.isShowMitraWalletConsentModal = this.showMitraWalletConsent;
        }
      },
    },
    '$route.name': {
      handler() {
        this.initMitraWalletConsent();
      },
    },
  },
  mounted() {
    this.$bus.$on('INIT_MITRA_WALLET_CONSENT', () => {
      this.initMitraWalletConsent();
    });
    this.initMitraWalletConsent();
  },
  methods: {
    ...mapActions('user', ['fetchLatestConsentAgreement']),
    async initMitraWalletConsent() {
      try {
        await this.fetchMitraAgent();
        if (this.fetchMitraAgentStatus === false) {
          this.isShowMitraWalletConsentModal = true;
        }
        if (this.showMitraWalletConsent) {
          await this.fetchLatestConsentAgreement({ productId: this.mitraWalletConsent.productId });
          this.isShowMitraWalletConsentModal = this.showMitraWalletConsent;
        } else {
          this.isShowMitraWalletConsentModal = false;
        }
      } catch (error) {
        this.isShowMitraWalletConsentModal = true;
      }
    },
  },
};
</script>

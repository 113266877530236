export default {
  SET_PROFILE(state, data) {
    state.profile = data;
  },
  SET_FETCH_STATUS(state, status) {
    state.fetchStatus = status;
  },
  SET_MITRA_AGENT(state, data) {
    state.mitraAgent = data;
  },
  SET_FETCH_MITRA_AGENT_STATUS(state, status) {
    state.fetchMitraAgentStatus = status;
  },
  SET_BUKASEND_MITRA_AGENT(state, data) {
    state.bukasendMitraAgent = data;
  },
  SET_FETCH_BUKASEND_MITRA_AGENT_STATUS(state, status) {
    state.fetchBukasendMitraAgentStatus = status;
  },
  SET_CONSENT_STATUS_AGREEMENT(state, { productId, key, value }) {
    if (!state.consentStatus.consentAgreement[productId]) {
      state.consentStatus.consentAgreement[productId] = {};
    }
    state.consentStatus.consentAgreement[productId][key] = value;
  },
  SET_CONSENT_STATUS_LOADING(state, { key, value }) {
    state.consentStatus[key].loading = value;
  },
  SET_CONSENT_STATUS_ERROR(state, { key, value }) {
    state.consentStatus[key].error = value;
  },
  SET_CONSENT(state, { key, value }) {
    state.consent[key] = value;
  },
  SET_CONSENT_AGREEMENT(state, { productId, value }) {
    state.consentAgreement[productId] = value;
  },
};
